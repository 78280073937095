import { Content } from "@/ui/Content";
import * as additionalComponents from "@/components/HomePage";
import { router } from "@/router";
import { stripTags } from "@/functions/stripTags";
import { getGlobal } from "@/functions/getGlobal";
import { jobs_helper } from "@/helpers/jobs_helper";
import sourceflowEnums from "@/sourceflow.enums";

export default function Page({ content }) {
  return (
    <>
      <Content items={content} additionalComponents={additionalComponents} />
    </>
  );
}

export async function getStaticProps({}) {
  const global = getGlobal();
  const jobs = jobs_helper.fetch({
    limit: 12,
    filter: (i) => JSON.stringify(i.categories).includes(sourceflowEnums.categories.sectors.external) &&
    JSON.stringify(i.categories).includes(sourceflowEnums.categories.locations.id),
  });

  const routerProp = router["home"];
  const titleKey = "page.home.banner.title";

  return {
    props: {
      meta: {
        en: {
          title: stripTags(global[`${titleKey}.en`]),
          canonical: `${sourceflowEnums.config.baseUrl}${routerProp["en"]}`,
        },
      },
      router: routerProp,
      content: [
        { component: "HomePageBannerSection" },
        { component: "SpecialismsSection" },
        { component: "HomePageContentSection", props: { className: "py-4 py-md-5" } },
        { component: "LatestJobsSection", props: { className: "py-4 py-md-5", items: jobs } },
        { component: "HomePageReasonsSection", props: { className: "py-4 py-md-5" } },
        { component: "AwardsSection", props: { indicators: true } },
      ],
    },
  };
}
