import { Col, Container, Dropdown, Row } from "react-bootstrap";
import clsx from "classnames";
import classes from "./styles.module.scss";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import { Form } from "@/ui/Form";
import UploadCV from "@/assets/UploadCV.svg";
import { useState } from "react";
import { sites_helper } from "@/helpers/sites_helper";
import sourceflowEnums from "@/sourceflow.enums";
import Close from "@/assets/Close.svg";
import { useLink } from "@/hooks/useLink";
import { JobSearchWidget } from "@/ui/Content/components";
import Image from "@/ui/Image";

export const HomePageBannerSection = ({ className }) => {
  const link = useLink();
  const locale = useLocale();
  const [showForm, setShowForm] = useState(false);
  const sites = sites_helper.fetch();

  return (
    <>
      <div className={clsx(className, classes.banner)}>
        <Container fluid>
          <Row className="px-3">
            <Col xs={12} className="mb-2 px-1">
              <div className={clsx(classes.banner__header, "p-4")} onClick={() => setShowForm(false)}>
                <DynamicText path={`page.home.banner.title.${locale}`} tag="h1" className="display-3 fw-bold" />
                <DynamicText path={`page.home.banner.text.${locale}`} tag="p" className="h3 text-primary" />
              </div>
            </Col>
            {!showForm && (
              <>
                <Col xs={12} md={8} className="px-1">
                  <div className={clsx(classes.banner__siteSelector, "text-overlay p-5")}>
                    <div className="d-flex flex-column justify-content-center align-items-center text-overlay__content">
                      <DynamicText
                        path={`page.home.banner.chooseRegion.${locale}`}
                        className="h3 text-secondary mb-4"
                      />
                      <Dropdown drop="down-centered">
                        <Dropdown.Toggle variant={false}>United Kingdom</Dropdown.Toggle>
                        <Dropdown.Menu flip={false}>
                          {sites.map((i, k) => (
                            <Dropdown.Item key={k} href={i.url} target="_blank" className="px-4">
                              {i.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <Image
                      className="text-overlay__background"
                      img={{
                        path: `page.home.banner.chooseRegion.bg`,
                        src: "/assets/banners/home/site_selector_bg.png",
                      }}
                      size="1198x313"
                      alt=""
                    />
                  </div>
                </Col>
                <Col xs={12} md={4} className="px-1">
                  <div className={clsx(classes.banner__uploadCV, "text-overlay p-5")}>
                    <a
                      href="#"
                      className="d-flex justify-content-center gap-3 text-overlay__content"
                      onClick={(e) => { setShowForm(!showForm); e.preventDefault(); }}
                    >
                      <DynamicText path={`page.home.banner.uploadCV.${locale}`} className="h3 text-secondary" />
                      <UploadCV />
                    </a>
                    <Image
                      className="text-overlay__background"
                      img={{ path: `page.home.banner.uploadCV.bg`, src: "/assets/banners/home/upload_cv_bg.png" }}
                      size="510x313"
                      alt=""
                    />
                  </div>
                </Col>
              </>
            )}
            {showForm && (
              <Col xs={12} className="px-1">
                <div className={clsx(classes.banner__uploadForm, "p-4 px-md-5")}>
                  <Close className={classes.banner__closeForm} width={15} onClick={() => setShowForm(false)} />
                  <Form
                    formId={sourceflowEnums.forms.register.homeBanner}
                    onSubmitDone={() => {
                      if (typeof window !== "undefined") {
                        window.location.href = link("cv-upload-home.form.complete");
                      }
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      <JobSearchWidget overflowTop={!showForm} />
    </>
  );
};
