import { Button, Col, Container, Row } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import { DynamicHtml } from "@/ui/DynamicHtml";
import classes from "./styles.module.scss";
import clsx from "classnames";
import { useLink } from "@/hooks/useLink";
import { useMediaQuery } from "@react-hook/media-query";
import { useEffect, useState } from "react";
import { Heading } from "@/ui/Heading";
import Image from "@/ui/Image";

export const HomePageServicesSection = ({ className }) => {
  const [isMobile, setMobile] = useState(true);
  const md = useMediaQuery("only screen and (min-width: 768px)");
  const locale = useLocale();
  const link = useLink();

  useEffect(() => {
    setMobile(!md);
  }, [md]);

  return (
    <div className={clsx(className, classes.services)}>
      <Container>
        {isMobile && (
          <Heading className="mb-4">
            <DynamicText tag="h2" path={`page.home.services.title.${locale}`} />
          </Heading>
        )}
        <div className="mx-2">
          <Row>
            <Col xs={12} lg={6} className="bg-light p-3 ps-4 py-md-5 pe-md-4 ps-md-5 text-overlay">
              {!isMobile && <DynamicText tag="h2" path={`page.home.services.title.${locale}`} />}
              <div className="text-overlay__content ms-0 ms-md-4">
                <DynamicText
                  tag="h3"
                  className="h4 mb-3 mb-md-5 mt-5 mt-md-0"
                  path={`page.home.services.candidates.title.${locale}`}
                />
                <DynamicHtml className="pb-4" path={`page.home.services.candidates.text.${locale}`} />
                <div className="d-flex justify-content-start mb-xl-5">
                  <Button href={link("jobs")} variant="outline-primary">
                    <DynamicText path={`page.home.services.candidates.button.${locale}`} />
                  </Button>
                </div>
              </div>
              <Image
                className="text-overlay__background"
                img={{
                  path: "page.home.services.candidates.bg",
                  src: "/assets/grid/bg.png",
                }}
                size="x650"
                alt=""
              />
            </Col>
            <Col xs={12} lg={6} className="p-5">
              <Image
                img={{
                  path: "page.home.services.candidates",
                  src: "/assets/home/services/1.jpg",
                }}
                size="x650"
                alt=""
              />
            </Col>
            <Col xs={12} lg={6} className="bg-secondary text-white p-3 ps-4 py-md-5 pe-md-4 ps-md-5 text-overlay">
              <div className="text-overlay__content">
                <DynamicText path={`page.home.services.businesses.title.${locale}`} tag="h4" className="mb-xl-5" />
                <DynamicHtml path={`page.home.services.businesses.text.${locale}`} className="pb-md-5 mb-xl-5" />
                <div className="d-flex justify-content-start my-xl-5">
                  <Button href={link("hiring")} variant="outline-light">
                    <DynamicText path={`page.home.services.businesses.button.${locale}`} />
                  </Button>
                </div>
              </div>
              <Image
                className="text-overlay__background"
                img={{
                  path: "page.home.services.businesses.bg",
                  src: "/assets/grid/transparent.png",
                }}
                size="x650"
                alt=""
              />
            </Col>
            <Col xs={12} lg={6} className="p-5">
              <Image
                img={{
                  path: "page.home.services.businesses.image",
                  src: "/assets/home/services/2.jpg",
                }}
                size="x650"
                alt=""
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
